<template>
  <section class="excerpt-add-wrapper">
    <b-row class="excerpt-add">

      <!-- Col: Left (Excerpt Container) -->
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-form @submit.prevent>
          <b-card
            no-body
            class="excerpt-preview-card"
          >
            <!-- Header -->
            <b-card-body class="excerpt-padding pb-3">
              <p class="card-text">
                Перенесите сюда Ваши файлы, чтобы загрузить их в Архив.<br><code>Внимание! файлы принимаются только в виде .zip архива, который, должен содержащать ".xml + .sig" либо ".zip/.xml + .sig"</code>
                <br><code>Принимаются только уникальные файлы подписанные ЭЦП, которые, ещё не были загружены в архив.</code>
              </p>
              <div class="mt-0">
                <Dropzone />
              </div>
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
    <excerpt-sidebar-add-new-customer />
  </section>
</template>

<script>
import Dropzone from '@core/layouts/components/dropzone/DropZone.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import {
  BRow, BCol, BCard, BCardBody, BForm, VBToggle,
} from 'bootstrap-vue'
import excerptStoreModule from '../excerptStoreModule'
import ExcerptSidebarAddNewCustomer from '../ExcerptSidebarAddNewCustomer.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BForm,
    ExcerptSidebarAddNewCustomer,
    Dropzone,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
  methods: {
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.excerptData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    removeItem(index) {
      this.excerptData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
  },
  setup() {
    const EXCERPT_APP_STORE_MODULE_NAME = 'app-excerpt'

    // Register module
    if (!store.hasModule(EXCERPT_APP_STORE_MODULE_NAME)) store.registerModule(EXCERPT_APP_STORE_MODULE_NAME, excerptStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EXCERPT_APP_STORE_MODULE_NAME)) store.unregisterModule(EXCERPT_APP_STORE_MODULE_NAME)
    })

    const dropzoneFile = ref('')
    const drop = e => {
      const foo = e.dataTransfer.files[0]
      dropzoneFile.value = foo
    }
    const selectedFile = () => {
      const foo = document.querySelector('.dropzoneFile').files[0]
      dropzoneFile.value = foo
    }

    const clients = ref([])
    store.dispatch('app-excerpt/fetchClients')
      .then(response => { clients.value = response.data })

    const itemFormBlankItem = {
      item: null,
      cost: 0,
      qty: 0,
      description: '',
    }

    const excerptData = ref({
      id: 5037,
      client: null,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],

      salesPerson: '',
      note: 'It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance projects. Thank You!',
      paymentMethod: null,
    })

    const itemsOptions = [
      {
        itemTitle: 'App Design',
        cost: 24,
        qty: 1,
        description: 'Designed UI kit & app pages.',
      },
      {
        itemTitle: 'App Customization',
        cost: 26,
        qty: 1,
        description: 'Customization & Bug Fixes.',
      },
      {
        itemTitle: 'ABC Template',
        cost: 28,
        qty: 1,
        description: 'Bootstrap 4 admin template.',
      },
      {
        itemTitle: 'App Development',
        cost: 32,
        qty: 1,
        description: 'Native App Development.',
      },
    ]

    const updateItemForm = (index, val) => {
      const { cost, qty, description } = val
      excerptData.value.items[index].cost = cost
      excerptData.value.items[index].qty = qty
      excerptData.value.items[index].description = description
    }

    const paymentMethods = [
      'Bank Account',
      'PayPal',
      'UPI Transfer',
    ]

    return {
      excerptData,
      clients,
      itemsOptions,
      updateItemForm,
      itemFormBlankItem,
      paymentMethods,
      dropzoneFile,
      drop,
      selectedFile,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.excerpt-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-excerpt.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
