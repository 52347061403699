<template>
  <div>
    <div
      ref="dropRef"
      class="dropzone custom-dropzone"
    />
  </div>
</template>

<script>
import {
  ref,
  onMounted,
  defineComponent,
  onUnmounted,
} from '@vue/composition-api'
import { Dropzone } from 'dropzone'
import useJwt from '@/auth/jwt/useJwt'
import { endpoint } from '@/@core/endpoint'

export default defineComponent({
  name: 'Dropzone',
  setup() {
    const dropRef = ref(null)
    let dz = ref(null)
    onMounted(() => {
      if (dropRef.value !== null) {
        dz = new Dropzone(dropRef.value,
          {
            url: `${endpoint()}/upload/`,
            paramName: 'file', // The name that will be used to transfer the file
            maxFilesize: 128.6, // MB
            acceptedFiles: '.zip',
            timeout: 1200000,
            parallelUploads: 10,
            createImageThumbnails: false,
            headers: {
              // eslint-disable-next-line prefer-template
              Authorization: 'Bearer ' + useJwt.getToken(),
            },
            dictDefaultMessage: 'Перетащите сюда .zip файлы для загрузки в архив',
            dictFallbackMessage: 'Ваш браузер не поддерживает загрузку файлов перетаскиванием.',
            dictFallbackText: 'Воспользуйтесь резервной формой ниже, чтобы загрузить файлы, как в старые времена.',
            dictFileTooBig: 'Файл слишком большой ({{filesize}}MiB). Максимальный размер файла: {{maxFilesize}}MiB.',
            dictInvalidFileType: 'Вы не можете загружать файлы этого типа.',
            dictResponseError: 'Сервер ответил кодом {{statusCode}}.',
            dictCancelUpload: 'Отменить загрузку.',
            dictUploadCanceled: 'Загрузка отменена.',
            dictCancelUploadConfirmation: 'Вы уверены, что хотите отменить эту загрузку?',
            dictRemoveFile: 'Удалить файл',
            dictMaxFilesExceeded: 'Вы не можете больше загружать файлы.',
            success: (file, fullData) => {
              const data = fullData.Data
              if (data.stat === 'ALSOK') {
                const pr = file.previewElement.getElementsByClassName('dz-details')[0]
                const fn = file.previewElement.getElementsByClassName('dz-filename')[0]
                const fntext = fn.innerText

                // eslint-disable-next-line no-useless-concat, prefer-template
                const html = '<div> (' + data.ms + ' sec)</div> ' + (pr.innerHTML !== undefined ? pr.innerHTML : '') + fntext + '<span class="badge mr-1 ml-auto badge-light-success badge-pill">' + data.msg + '</span>'
                pr.innerHTML = html
                if (pr.getElementsByClassName('dz-filename') && pr.getElementsByClassName('dz-filename').length > 0) {
                  // console.log(pr.getElementsByClassName('dz-filename'))
                  pr.getElementsByClassName('dz-filename')[0].remove()
                }
              } else {
                const pr = file.previewElement.getElementsByClassName('dz-details')[0]
                const fn = file.previewElement.getElementsByClassName('dz-filename')[0]
                const fntext = fn.innerText

                // eslint-disable-next-line no-useless-concat, prefer-template
                const html = '<div>(' + data.ms + ' sec)</div> ' + (pr.innerHtml !== undefined ? pr.innerHtml : '') + fntext + " <span class='badge mr-1 ml-auto badge-light-danger badge-pill'>ошибка</span>"
                pr.innerHTML = html
                if (pr.getElementsByClassName('dz-filename') && pr.getElementsByClassName('dz-filename').length > 0) {
                  // console.log(pr.getElementsByClassName('dz-filename'))
                  pr.getElementsByClassName('dz-filename')[0].remove()
                }
                if (data.msg.indexOf('<!DOCTYPE html>') !== -1) {
                  // eslint-disable-next-line padded-blocks,no-param-reassign
                  data.msg = 'Файл не корректный, пожалуйста загрузите исходники выписки полученные от Росреестра'
                }
                file.previewElement.classList.add('dz-error')

                const errText = file.previewElement.querySelectorAll('span[data-dz-errormessage]')[0]
                errText.innerText = data.msg
              }
            },
            error: (file, msg) => {
              // console.log('error', msg)
              let localMsg = msg.ExceptionMessage ? msg.ExceptionMessage : msg
              if (localMsg.indexOf('<!DOCTYPE html>') !== -1) {
                localMsg = 'Файл не корректный, пожалуйста загрузите исходники выписки полученные от Росреестра'
              }
              const pr = file.previewElement.getElementsByClassName('dz-details')[0]
              const fn = pr.getElementsByClassName('dz-filename')[0]
              const fntext = fn.innerText
              const html = pr.innerHTML + fntext
              pr.innerHTML = html
              if (pr.getElementsByClassName('dz-filename') && pr.getElementsByClassName('dz-filename').length > 0) {
                pr.getElementsByClassName('dz-filename')[0].remove()
              }
              file.previewElement.classList.add('dz-error')
              const errText = file.previewElement.querySelectorAll('span[data-dz-errormessage]')[0]
              errText.innerText = localMsg
            },
          })
      }
    })
    onUnmounted(() => {
      dz.destroy()
    })
    return {
      dropRef,
    }
  },
})
</script>

<style>
.dropzone .dz-preview {
        width: 100%;
        margin: 0px !important;
        border-bottom: 2px dashed #7367f02e !important;
        padding: 2px;
        min-height: 30px;
    }

        .dropzone .dz-preview:last-child {
            border-bottom: none;
        }

    .dz-image {
        display: none !important;
    }

    .dropzone .dz-preview .dz-details {
        position: relative;
        top: unset;
        left: unset;
        right: unset;
        text-align: left !important;
        font-size: 14px;
    }

    .dropzone .dz-preview:not(.dz-processing) .dz-progress {
        -webkit-animation: none;
        -moz-animation: none;
        -ms-animation: none;
        -o-animation: none;
        animation: none;
    }

    .dz-details div {
        display: inline-block;
    }

    .dz-error-message {
        position: relative !important;
        border-radius: unset !important;
        top: unset !important;
        left: unset !important;
        width: 100% !important;
    }

    .dropzone .dz-preview .dz-details {
        padding: 0px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .dropzone .dz-preview .dz-error-message {
        opacity: 1 !important;
        z-index: 996;
    }

        .dropzone .dz-preview .dz-error-message:after {
            display: none;
        }

    .dropzone .dz-preview .dz-details .dz-filename:hover {
        overflow: unset;
        text-overflow: ellipsis;
    }

    .dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
        overflow: unset;
    }

    .dropzone .dz-preview .dz-details .dz-size {
        margin-bottom: 0px;
    }

    .dropzone .dz-preview.dz-complete .dz-progress {
        display: none;
    }

    .dropzone .dz-preview .dz-success-mark, .dropzone .dz-preview .dz-error-mark {
        pointer-events: none;
        z-index: 500;
        position: absolute;
        display: block;
        top: 33px;
        left: 8px;
        margin-left: -27px;
        margin-top: -27px;
    }

        .dropzone .dz-preview .dz-success-mark svg, .dropzone .dz-preview .dz-error-mark svg {
            width: 40px !important;
            height: 40px !important;
        }

    .dropzone .dz-preview .dz-details .btn {
        padding-left: 5px;
        padding-right: 5px;
    }

    .dropzone .dz-preview .dz-progress {
        z-index: 1000;
        pointer-events: none;
        position: absolute;
        height: 6px;
        left: unset;
        bottom: -2px;
        top: unset;
        margin-bottom: 0px;
        width: 100%;
        margin-left: unset;
    }

        .dropzone .dz-preview .dz-progress .dz-upload {
            background-image: linear-gradient( 45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
            background-size: .75rem .75rem;
            -webkit-animation: progress-bar-stripes 1s linear infinite;
            animation: progress-bar-stripes 1s linear infinite;
            background-color: #7367F0;
            color: #fff;
        }

    @@-webkit-keyframes progress-bar-stripes {
        from {
            background-position: .75rem 0
        }

        to {
            background-position: 0 0
        }
    }

    @@keyframes progress-bar-stripes {
        from {
            background-position: .75rem 0
        }

        to {
            background-position: 0 0
        }
    }
</style>
